<template>
    <div class="maintenance">
        <itc-navigation></itc-navigation>
            <div class="main">
                <div class="m-5 jumbotron text-center">
                    <i class="fas fa-exclamation-triangle fa-3x mb-3"></i>
                    <h1 class="jumbotron-heading">
                        ¡Página en mantenimiento!
                        <hr class="my-4">
                    </h1>
                    Pronto tendremos novedades para mostrarte
                </div>
            </div>
        <itc-footer></itc-footer>
    </div>
</template>

<script>
import Navigation from '../components/shared/shop/Navigation'
import Footer from '../components/shared/shop/Footer'

export default {
  components: {
    'itc-navigation': Navigation,
    'itc-footer': Footer
  }
}
</script>

<style lang="scss" scoped>
.main{
    height: 82vh;
}
</style>